
import React, { useState, useEffect } from 'react';
// import { withRouter } from 'react-router-dom'
import SimpleSlider from '../../components/common/SimpleSlider.js'
import { BsQuestionCircle } from '@react-icons/all-files/bs/BsQuestionCircle'
import { GiCheckeredFlag } from "@react-icons/all-files/gi/GiCheckeredFlag";
import { ImFilter } from '@react-icons/all-files/im/ImFilter'
import DefaultLayout from '../../layouts/DefaultLayout'
import Link from 'next/link'
import PAGEURL from '../../constants/PAGEURL'
import Head from 'next/head'
import Image from 'next/image'
import Circles from '../../components/common/Circles'

export default function Home() {
  const [scrollThreshold, setScrollThreshold] = useState(false);
   useEffect(() => {
     let scrollDownThreshold = 250
     let scrollUpThreshold = 225
     let isMobile = window.innerWidth < 768
     window.addEventListener("scroll", () => {
       if (isMobile) {
         scrollDownThreshold = 625
         scrollUpThreshold = 600
       }
       if (document.getElementById('circle1') && window.scrollY > scrollDownThreshold && !document.getElementById('circle1').classList.contains('circle1Combine')) {
          document.getElementById('circle1').classList.add('circle1Combine');
          document.getElementById('circle2').classList.add('circle2Combine');
          document.getElementById('circle3').classList.add('circle3Combine');
          document.getElementById('circle4').classList.add('circle4Combine');
          document.getElementById('circle5').classList.add('circle5Combine');
          document.getElementById('circle6').classList.add('circle6Combine');
          document.getElementById('circle7').classList.add('circle7Combine');
          document.getElementById('circle8').classList.add('circle8Combine');
          document.getElementById('circle9').classList.add('circle9Combine');
          document.getElementById('circle10').classList.add('circle10Combine');
          document.getElementById('circle11').classList.add('circle11Combine');
          document.getElementById('circle12').classList.add('circle12Combine');
          document.getElementById('circle13').classList.add('circle13Combine');
          document.getElementById('circle14').classList.add('circle14Combine');
          document.getElementById('circle15').classList.add('circle15Combine');
          document.getElementById('circle16').classList.add('circle16Combine');
          document.getElementById('circle17').classList.add('circle17Combine');
          document.getElementById('circle18').classList.add('circle18Combine');
          document.getElementById('circle19').classList.add('circle19Combine');
          document.getElementById('circles-tagline').classList.add('show-tagline');
       }
       if (document.getElementById('circle1') && window.scrollY < scrollUpThreshold && document.getElementById('circle1').classList.contains('circle1Combine')) {
          document.getElementById('circle1').classList.remove('circle1Combine');
          document.getElementById('circle2').classList.remove('circle2Combine');
          document.getElementById('circle3').classList.remove('circle3Combine');
          document.getElementById('circle4').classList.remove('circle4Combine');
          document.getElementById('circle5').classList.remove('circle5Combine');
          document.getElementById('circle6').classList.remove('circle6Combine');
          document.getElementById('circle7').classList.remove('circle7Combine');
          document.getElementById('circle8').classList.remove('circle8Combine');
          document.getElementById('circle9').classList.remove('circle9Combine');
          document.getElementById('circle10').classList.remove('circle10Combine');
          document.getElementById('circle11').classList.remove('circle11Combine');
          document.getElementById('circle12').classList.remove('circle12Combine');
          document.getElementById('circle13').classList.remove('circle13Combine');
          document.getElementById('circle14').classList.remove('circle14Combine');
          document.getElementById('circle15').classList.remove('circle15Combine');
          document.getElementById('circle16').classList.remove('circle16Combine');
          document.getElementById('circle17').classList.remove('circle17Combine');
          document.getElementById('circle18').classList.remove('circle18Combine');
          document.getElementById('circle19').classList.remove('circle19Combine');
          document.getElementById('circles-tagline').classList.remove('show-tagline');
       }
     });
   }, []);

  return (
    <div className="home">
      <Head>
        <title>{'SimplyBuy | Personalized Product Pickers'}</title>
        <meta
          name="description"
          content={
            'Discover your perfect match! Our product picker tools analyze your preferences to find the ideal products just for you.'
          }
        />
      </Head>
      <div className="home-container">
        <div className="img-container">
          <Image
            src={`/simplybuy-logo.webp`}
            width={300}
            height={60}
            priority={true}
            alt="SimplyBuy"
            placeholder="blur"
            blurDataURL={`/simplybuy-logo.webp`}
          />
        </div>
        <h1>
          <div className="tagline">
            <div>Get personal recommendations for products in just minutes!</div>
          </div>
        </h1>
        <div className="psg-container home-page orange">
          <div className="select-product">Select a Product Picker</div>
          <div>
            <div className="product-category mt-md pch">Outdoor/Sports Gear</div>
            <div className="products-flex max-size test123">
              <div className="product-container test1">
                <Link href={PAGEURL.PRODUCT_PICKER('mountain-bike', '0')}>
                  <div className="product-option test7">Mountain Bikes</div>
                </Link>
              </div>
              <div className="product-container test2">
                <Link href={PAGEURL.PRODUCT_PICKER('pickleball-paddle', '0')}>
                  <div className="product-option test12">Pickleball Paddles</div>
                </Link>
              </div>
              <div className="product-container test3">
                <Link href={PAGEURL.PRODUCT_PICKER('ski', '0')}>
                  <div className="product-option test8">Skis</div>
                </Link>
              </div>
              <div className="product-container test4">
                <Link href={PAGEURL.PRODUCT_PICKER('snowboard', '0')}>
                  <div className="product-option test9">Snowboards</div>
                </Link>
              </div>
              <div className="product-container test5">
                <Link href={PAGEURL.PRODUCT_PICKER('snowboard-binding', '0')}>
                  <div className="product-option test10">Snowboard Bindings</div>
                </Link>
              </div>
              <div className="product-container test6">
                <Link href={PAGEURL.PRODUCT_PICKER('snowboard-boots', '0')}>
                  <div className="product-option test11">Snowboard Boots</div>
                </Link>
              </div>
            </div>
          </div>
          <div>
            <div className="product-category pch2">Tech</div>
            <div className="products-flex max-size">
              <div className="product-container test13">
                <Link href={PAGEURL.PRODUCT_PICKER('headphones', '0')}>
                  <div className="product-option">Headphones</div>
                </Link>
              </div>
              <div className="product-container test14">
                <Link href={PAGEURL.PRODUCT_PICKER('monitor', '0')}>
                  <div className="product-option">Monitors</div>
                </Link>
              </div>
              <div className="product-container test15">
                <Link href={PAGEURL.PRODUCT_PICKER('tv', '0')}>
                  <div className="product-option">TVs</div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Circles/>
        <div className="pp-symbols">
          <div className="process">How does SimplyBuy work?</div>
          <div className="symbols-container">
            <div className="symbol">
              <div className="stat-block">
                <div className="stat-label">
                  <div className="icon-wrapper">
                    <BsQuestionCircle />
                  </div>
                  <div className="stat-name">Learn</div>
                </div>
                <div className="">
                  Answer questions to let us know your desired specs. We don&#39;t expect everyone to be experts. You will be provided with all the necessary info to answer any technical questions.
                </div>
              </div>
            </div>
            <div className="symbol">
              <div className="stat-block">
                <div className="stat-label">
                  <div className="icon-wrapper">
                    <Image
                      src={`/simplybuy-favicon-copy.png`}
                      width={28}
                      height={28}
                      priority={false}
                      alt="SimplyBuy Logo"
                      blurDataURL={'/simplybuy-favicon-copy.png'}
                    />
                  </div>
                  <div className="stat-name">Filter</div>
                </div>
                <div className="">
                  We filter down your results to only products that fit your needs. When possible, we give a range of products. We let you choose between value picks and high-end picks based on your selected filters.
                </div>
              </div>
            </div>
            <div className="symbol">
              <div className="stat-block">
                <div className="stat-label">
                  <div className="icon-wrapper">
                    <GiCheckeredFlag />
                  </div>
                  <div className="stat-name">Buy</div>
                </div>
                <div className="">
                  Our process is the quickest and most efficient way to find the perfect products for you. Especially for those that are new to the category.
                </div>
              </div>
            </div>
          </div>
        </div>
        <SimpleSlider />
      </div>
    </div>
  )
}

// Home.Layout = DefaultLayout

// export default Home
